<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-5">
        <v-btn
          v-if="$temPermissao('camera-insercao')"
          color="info"
          small
          @click="mostrarDialogAdicionar"
        >
          <v-icon left> mdi-plus </v-icon>
          Adicionar
        </v-btn>

        <p v-if="cameras.length <= 0 && !carregandoBuscar" class="mt-2">
          <i>Nenhuma câmera encontrada</i>
        </p>

        <v-skeleton-loader
          v-if="carregandoBuscar"
          type="list-item-two-line"
          class="mt-2"
        />

        <v-row
          v-else-if="
            $temPermissao('ponto-captura-listagem-cameras') ||
            $temPermissao('camera-listagem-cameras')
          "
          class="mt-2 responsividade"
        >
          <v-col
            v-for="camera in cameras"
            :key="camera.id"
            cols="6"
            class="py-0"
          >
            <v-list v-if="$temPermissao('camera-visualizacao')" two-line>
              <v-list-item
                :key="camera.id"
                @click="mostrarDialogEditar(camera)"
              >
                <v-list-item-content>
                  <v-list-item-content class="font-weight-bold"
                    >Identificador -
                    {{ camera.identificador }}</v-list-item-content
                  >
                  <v-list-item-content>
                    {{ camera.descricao }}
                  </v-list-item-content>
                  <v-list-item-content>
                    {{ camera.endereco_ip || "" }}
                  </v-list-item-content>
                  <v-list-item-content class="verde" v-if="camera.status == 1">
                    Ativo
                  </v-list-item-content>

                  <v-list-item-content class="vermelho" v-else>
                    Inativo
                  </v-list-item-content>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <pontos-captura-cameras-campos
      :mostrar="mostrarDialogCampos"
      :camera="cameraEditar"
      :validacao-formulario="validacaoFormulario"
      :carregando-salvar="carregandoSalvar"
      @fechar="mostrarDialogCampos = false"
      @salvar="salvarCamera"
    />
  </v-container>
</template>

<script>
import apiPontosCaptura from "@/api/pontos-captura";
import apiCameras from "@/api/cameras";

export default {
  components: {
    PontosCapturaCamerasCampos: () =>
      import("@/views/pages/pontos-captura/cameras/PontosCapturaCamerasCampos"),
  },

  props: {
    pontoCaptura: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      ativo: 1,
      ativo: null,
      inativo: null,
      carregandoBuscar: false,
      carregandoSalvar: false,
      mostrarDialogCampos: false,
      cameras: [],
      cameraEditar: null,
      validacaoFormulario: {},
    };
  },

  mounted() {
    this.buscarCameras();
  },

  methods: {
    async buscarCameras() {
      try {
        this.carregandoBuscar = true;
        const resposta = await apiPontosCaptura.listarCameras(
          this.pontoCaptura.id
        );
        this.cameras = resposta.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.carregandoBuscar = false;
      }
    },

    mostrarDialogAdicionar() {
      this.cameraEditar = { status: "1" };
      this.mostrarDialogCampos = true;
    },

    mostrarDialogEditar(camera) {
      this.cameraEditar = { ...camera };
      this.mostrarDialogCampos = true;
    },

    async salvarCamera(camera) {
      try {
        this.carregandoSalvar = true;
        camera.ponto_captura_id = this.pontoCaptura.id;

        let mensagem;

        if (camera.id) {
          mensagem = this.$mensagens._("sucesso_editar");
          await apiCameras.atualizar(camera.id, camera);
        } else {
          mensagem = this.$mensagens._("sucesso_adicionar");
          await apiCameras.inserir(camera);
        }

        this.mostrarDialogCampos = false;
        this.$snackbar.mostrar({ cor: "success", mensagem });
        this.buscarCameras();
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
<style scoped>
.verde {
  color: green;
}
.vermelho {
  color: red;
}
@media (max-width: 400px) {
  .responsividade { 
    font-size: 0.75rem
  }
}
</style>
